/* succes.css */

.succes {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 320px;
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Optional: You can style the email link differently */
  a[href^="mailto:"] {
    color: #007bff;
    text-decoration: none;
  }
  
  a[href^="mailto:"]:hover {
    text-decoration: underline;
  }
  