.home-div{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: radial-gradient(157.60% 110.96% at 50.13% 1.36%, #000 44.30%, rgba(0, 0, 0, 0.70) 67.68%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(91deg, rgba(112, 7, 233, 0.527) 0%, rgba(63, 20, 144, 0.678) 100%);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 15px;
  padding-bottom: 250px;
}

.home-div-1{
  display: flex;
  width: 728.41px;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.home-div-1-block-1{
  display: flex;
  width: 737px;
  padding: 19px 0px 0px 0px;
  align-items: flex-start;
}

.home-div-1-block-1-h{
  width: 737px;
  padding: 0px 0.91px 1px 1px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
}

.home-div-1-block-1-h h1{
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
}

.yellow{
  color: #830FFE;
  font-family: Inter;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 78px;
}
.line{
 margin-right: auto;
 margin-left: auto;
 margin-bottom: 5px;
}

.desc{
  display: flex;
  width: 630px;
  height: 51px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.desc p{
  color: #D4DCE5;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 125% */
}

.cta-block{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15.01px;
  margin-bottom: 45px;
}

.cta-block-bouton{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 210.97px;
  padding: 13px 146.77px 10.59px 147.2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #830FFE;
  box-shadow: 0px 0px 20px 0px #830FFE;
  color: #ffffff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-top: 40px;
  line-height: 25.6px; /* 160% */
  transition: transform 0.2s ease; /* Ajouter la transition pour l'effet hover */
}

.cta-block-bouton a{
  text-decoration: none;
  color: white;
}

.cta-block-bouton:hover{
  transform: translateY(-5px); /* Déplacer le bouton de 5 pixels vers le haut (vers le haut, car la valeur est négative) */
}

.cta-block-arguments{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 446.97px;
  padding-right: 0px;
  align-items: center;
  gap: 24.99px;
  
}

.cta-block-arguments-1{
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  color: #D4DCE5;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
}

.home-div-2{
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #F9F9F9;
}

.home-div-discord{
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 16px;
}

.home-div-discord-container{
  display: flex;
  padding-top: 69px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  
}

.home-div-discord-container img{
display: flex;
width: 65px;
max-width: 600px;
justify-content: center;
align-items: flex-start;
}

.discord-div-container-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.discord-div-container-text h1{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #2B2B2B;
  text-align: center;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 160% */
}

.discord-div-container-text p{
  display: flex;
  padding: 0px 1.98px 1.17px 2.02px;
  justify-content: center;
  align-items: flex-start;
  color: #2B2B2B;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; /* 160% */
}

.discord-div-container-text button{
  display: flex;
  width: 162.78px;
  padding: 9px 19.78px 10.59px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #830FFE;
  color: white;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
}

.showcase-space-b1{
  max-width: 1000px;
  background-image: linear-gradient(135deg, #8C22F9, #6407FA);
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  transform: translate(0, -200px);
}

.showcase-space-b2{
  background-color: #000;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.showcase-space-b2 img{
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.home-div-trusted{
  transform: translate(0, -95px);
}

.home-div-trusted h1{
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.home-div-cta-post{
  background: linear-gradient(180deg, #000 0%, #3E1490 474.33%);
  padding-top: 40px;
  padding-bottom: 40px;
}

.home-div-cta-post-block{
  margin-top: 15px;
  display: flex;
  width: 944px;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.home-div-cta-post-block h2{
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 62.5px; /* 125% */
  margin-top: 20px;
  margin-bottom: 25px;
}

.home-div-cta-post-block h1{
  color: #B9B9B9;
  text-align: center;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 2px; /* 100% */
}

.home-div-cta-post-block button{
  display: flex;
  width: 196px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #000;
  background: linear-gradient(180deg, #8C22F9, #6407FA, #4F04D8);
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
}

.home-div-cta-discord{
  display: flex;
  margin-top: 85px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 32px 34px 32px;
  align-items: center;
  gap: 307.984px;
  border-radius: 16px;
  border-bottom: 2px solid #22242E;
  background: #111218; 
  width: 1218px;
  height: 100px;
}

.home-div-cta-discord-1{
  display: flex;
  padding-left: 0px;
  align-items: flex-start;
}

.home-div-cta-discord-1-text{
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-div-cta-discord-1-text h1{
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
}

.home-div-cta-discord-1-text p{
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
  width: auto;
}

.home-div-cta-discord button{
  display: flex;
  width: 196px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #000;
  background: linear-gradient(180deg, #8C22F9, #6407FA, #4F04D8);
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 768px) {

  .home-div-1 {
    width: 90%; /* Ajustement de la largeur pour les écrans mobiles */
  }
  .home-div-1-block-1{
    width: 90%;
    height: auto;
  }
  .home-div-1-block-1-h{
    width: 90%;
  }
  .home-div-1-block-1-h h1 {
    font-size: 36px; /* Réduire la taille de la police pour les écrans mobiles */
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }
  .yellow{
    font-size: 40px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .desc{
    width: 90%;
  }
  .desc p {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px; /* Réduire la taille de la police pour les écrans mobiles */
  }
  .cta-block {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .cta-block-bouton {
    margin-left: auto;
    margin-right: auto;
    width: 42px;
  }
  .cta-block-arguments{
    width: 90%;
  }
  .cta-block-bouton a {
    text-decoration: none;
    color: white;
  }

  .showcase-space-b1 {
    padding: 0; /* Supprimer l'espacement pour les écrans mobiles */
  }

  .home-div-trusted h1 {
    font-size: 18px; /* Réduire la taille de la police pour les écrans mobiles */
  }

  .home-div-cta-post h2 {
    font-size: 32px; /* Réduire la taille de la police pour les écrans mobiles */
  }

  .home-div-cta-discord {
    width: 90%; /* Ajustement de la largeur pour les écrans mobiles */
  }

  .home-div-cta-discord-1-text h1 {
    font-size: 18px; /* Réduire la taille de la police pour les écrans mobiles */
  }
  .home-div-cta-post-block {
    width: 90%;
  }
  .home-div-cta-discord{
    width: 70%;
    height: auto;
    display: block;
  }
  .home-div-cta-discord-1-text{
    width: 90%;
  }
  .home-div-cta-discord-1-text p {
    font-size: 14px; /* Réduire la taille de la police pour les écrans mobiles */
  }
}