@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  /* Style de la barre de navigation */
  .navbar {
    background-color: #000;
    display: flex;
    width: auto;
    max-width: auto;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #202020;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .logo {
    width: 22%;
    max-width: 1344px;
    flex-shrink: 0;
  }
  
  .nav-links {
    list-style: none;
    margin: 0;
    
    padding: 0;
  }
  .nav-link{
    text-decoration: none;
    color: white;
  }
  .nav-links li {
    margin-top: 10px;
    display: inline-block;
    
    margin-left: 20px;
    color: #1E1E1E;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px; /* 160% */
    transition: transform 0.2s ease; /* Ajouter la transition pour l'effet hover */
  }

  .nav-links li:hover {
    transform: translateY(-5px); /* Déplacer le bouton de 5 pixels vers le haut (vers le haut, car la valeur est négative) */
  }
  
  .nav-links li:first-child {
    margin-left: 0;
  }
  
  .nav-links1 {
    color: #ffffff;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .nav-links2 {
    color: white;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .nav-links li a:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  

/* Styles de base du bouton */
.bouton {
  display: flex;
  padding: 11px 20.09px 11.59px 21px;
  margin-left: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #830FFE;
  background: linear-gradient(180deg, rgba(140, 34, 249, 0.2), rgba(100, 7, 250, 0.2), rgba(79, 4, 216, 0.2));
  color: #2B2B2B;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
  transition: transform 0.2s ease; /* Ajouter la transition pour l'effet hover */
}

/* Style du hover */
.bouton:hover {
  transform: translateY(-5px); /* Déplacer le bouton de 5 pixels vers le haut (vers le haut, car la valeur est négative) */
}

/* Styles de base du bouton */
.bouton2 {
  display: flex;
  padding: 11px 19.84px 11.59px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: linear-gradient(180deg, #8C22F9, #6407FA, #4F04D8);
  color: white;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
  transition: transform 0.2s ease; /* Ajouter la transition pour l'effet hover */
}

/* Style du hover */
.bouton2:hover {
  transform: translateY(-5px); /* Déplacer le bouton de 5 pixels vers le haut (vers le haut, car la valeur est négative) */
}

@media screen and (max-width: 768px) {
  .container{
    display: block;
    align-items: center;
  }
  .logo{
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-links{
    display: grid;
    display: none;
    margin-right: auto;
    margin-left: auto;
  }
  .nav-links li{
    margin-left: auto;
    margin-right: auto;
  }
}