.add-div{
  margin-left: auto;
  background: linear-gradient(180deg, #000 0%, #3E1490 474.33%);
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.form-container {
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 100px;
    padding-right: 100px;
    border-radius: 10px;
    padding-bottom: 25px;
    box-shadow: 0 30px 30px -25px rgba(22, 22, 29, 0.25);
    border-bottom: 2px solid #22242E;
    background: #111218; 
  }
  
  h2 {
    font-size: 44px;
    text-align: center;
    margin-bottom: 20px;
    color: white;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: 78px; /* 134.483% */
  }
  
  label {
    color: white;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  /* Utilisation de flexbox pour aligner les champs en dessous des étiquettes */
  .input-container {
    display: flex;
    flex-direction: column;
  }
  
  input,
  textarea {
    width: 300px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: white;
  }
  
  button {
    background: linear-gradient(180deg, #8C22F9, #6407FA, #4F04D8);
    color: #fff;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    width: 100%;
  }
  
  /* Darker background on mouse-over */
  button:hover {
    background-color: RoyalBlue;
  }
  