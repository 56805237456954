@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

body{
  background-color: #F9F9F9;
}

.background{
  padding-top: 20px;
  background: radial-gradient(157.60% 110.96% at 50.13% 1.36%, #000 44.30%, rgba(0, 0, 0, 0.70) 67.68%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(91deg, rgba(112, 7, 233, 0.527) 0%, rgba(63, 20, 144, 0.678) 100%);
}

header {
  border-bottom: 2px solid #22242E;
  background: #111218; 
  color:  white;
  padding: 20px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 20px;
  text-align: center;
  font-family: Inter;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(22, 21, 18, 0.09);
}
  
  .script-container {
    min-height: 550px;
    padding: 25px;
    background-color: #F9F9F9;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  /* Remplacer le style de .script par le style de .information */
  .information {
    border-bottom: 2px solid #c0c0c0;
    background: #F9F9F9; 
    padding: 1.5rem;
    border-radius: 16px;
    color: #000;
    /* border: 1px solid #E6E6E6; */
    box-shadow: 0px 0px 20px 0px rgba(22, 21, 18, 0.09);
    width: 300px;
  }
  
  .information .tag {
    display: inline-block;
    background-color: var(--c-titan-white);
    color: var(--c-indigo);
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0.5em 0.75em;
    line-height: 1;
    border-radius: 6px;
  }
  
  .information .tag + * {
    margin-top: 1rem;
  }
  
  .information .title {
    font-size: 1.5rem;
    color: var(--c-void);
    line-height: 1.25;
    height: 50px;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.6px; /* 160% */
  }
  
  .information .title + * {
    margin-top: 1rem;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.6px; /* 160% */
  }
  
  .information .info {
    color: rgb(92, 92, 92);
    max-height: 160px;
    height: 160px;
  }
  
  .information .info + * {
    margin-top: 1.25rem;
  }

  .information .infoz {
    color: rgb(92, 92, 92);
  }
  
  .information .infoz + * {
    margin-top: 1.25rem;
  }
  
  .information .details {
    display: flex;
    gap: 1rem;
  }
  
  .information .details div {
    padding: 0.75em 1em;
    background-color: var(--c-titan-white);
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.125em;
    flex-basis: 50%;
  }
  
  .information .details dt {
    font-size: 0.875rem;
    color: var(--c-cold-purple);
  }
  
  .information .details dd {
    color: var(--c-indigo);
    font-weight: 600;
    font-size: 1.25rem;
  }
  
  .information img{
    width: 100%;
    height: 178px;
    border-radius: 5px;
    transition: 200ms;
  }

  .information img:hover{
    border-radius: 5px;
    opacity: 90%;
    scale: 105%;
    transition: 200ms;
  }
  
  input[type="text"] {
    width: 60%;
    padding: 14px;
    font-size: 16px;
    color: white;
    border: 1px solid #830FFE;
    background: linear-gradient(180deg, rgba(140, 34, 249, 0.2), rgba(100, 7, 250, 0.2), rgba(79, 4, 216, 0.2));
    margin-bottom: 20px;
    border-radius: 6px; 
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.6px; /* 160% */
  }

  input:focus {
    width: 60%;
    padding: 14px;
    font-size: 16px;
    border: 2px solid #830FFE;
    background: rgba(229, 28, 255, 0.2);
    margin-bottom: 20px;
    border-radius: 6px; 
    color:white;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.6px; /* 160% */
    outline: none; 
  }


/* Style buttons */
.btn {
  background-color: #830FFE;
  border-radius: 13px;
  border: none;
  color: white;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
}

/* Darker background on mouse-over */
.btn:hover {
  background-color: #6a24b6;
}