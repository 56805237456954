footer {
  display: flex;
  /*background-color: #333; /* Couleur de fond du pied de page */
  background: #0C041E;
  color: #fff; /* Couleur du texte */
  padding: 20px;
  height: auto;
  width: auto;
}

.footer-1{
  margin-top: 45px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;  
  margin-bottom: 25px;
}

.footer-1-text{
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;

}

.footer-1-text img{
  width: 20%;
  margin-bottom: 20px;
}

.footer-1-text h1{
  color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 104.167% */
}